<template>
  <div class="page-wrapper theme-green-forest">
    <Header :user="user" />

    <div class="profile">
      <button @click="$router.back()" class="settings__back-link btn-reset">
        <img
          src="@/static/settings/arrow.svg"
          alt="Arrow back"
          title="Back to homepage"
        />
      </button>
      <div class="profile__container container container--big">
        <div class="profile__top">
          <div class="profile__top-title">Profile</div>
        </div>
        <div class="profile__links">
          <router-link to="/profile/dashboard" class="profile__link">
            Dashboard
          </router-link>
          <router-link to="/profile/library" class="profile__link">
            Library
          </router-link>
          <router-link to="/profile/achievements" class="profile__link">
            Achievements
          </router-link>
          <router-link to="/profile/notes" class="profile__link">
            Notes
          </router-link>
          <router-link to="/profile/worksheets" class="profile__link">
            Worksheets
          </router-link>
        </div>
      </div>
    </div>

    <router-view style="margin-bottom: auto" />

    <Footer />
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
export default {
  name: 'ProfileLayout',
  components: { Header, Footer },
  props: ['user'],
}
</script>

<style>
.profile {
  position: relative;
  margin-bottom: auto;
}
</style>
